import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PlanPublishedExpanded from '../components/PlanPublishedExpanded';
import UserCaption from '../components/UserCaption';
import StepInput from '../components/StepInput';
import TopicSelector from '../components/TopicSelector';
import {
  stepProposalCreation,
  publishedPlanSelection,
  publishedPlansOverride,
  topicsOverride,
  toggleTopic,
} from '../redux/actions';
import { PlanProps } from '../utils/propTypes';
import {
  findPlan,
  truncateString,
  planStepCount,
  planTaken,
} from '../utils/planHelpers';
import { StyledPlanPublished } from '../components/plans';
import StepTake from '../components/StepTake';

const StyledButton = styled.button`
  flex: 0 0 auto;
  position: relative;
`;

const StyledCount = styled.span`
  display: block;
  font-size: 12px;
  position: absolute;
  text-align: center;
  top: 5px;
`;

const planMatchesTopics = (plan, selectedTopics) => {
  const items = [plan].concat(plan.steps);
  for (let i = 0; i < items.length; i++) {
    const element = items[i];
    for (let j = 0; j < element.topics.length; j++) {
      const topic = element.topics[j];
      if (selectedTopics.indexOf(topic) > -1) return true;
    }
  }
  return false;
};

const planVisible = (
  plan,
  shownUserID,
  selectedPublishedPlanID,
  selectedTopics
) => {
  if (plan.id === selectedPublishedPlanID) {
    return true;
  }
  if (plan.userID !== shownUserID) {
    return false;
  }
  if (selectedTopics.length === 0) {
    return true;
  }
  return planMatchesTopics(plan, selectedTopics);
};

class Stream extends React.Component {
  constructor(props) {
    super(props);

    this.fetchAddress = () => {
      const { shownUserID } = this.props;
      return shownUserID > 0
        ? `/user_states/${shownUserID}.json`
        : '/published_plans.json';
    };

    this.fetchPublishedPlans = () => {
      const { dispatch } = this.props;
      fetch(this.fetchAddress())
        .then((res) => res.json())
        .then((result) => {
          dispatch(publishedPlansOverride(result.publishedPlans));
          dispatch(topicsOverride(result.topics));
        });
    };

    this.selectCallback = (selectedPublishedPlanID) => {
      const { dispatch } = this.props;
      dispatch(publishedPlanSelection(selectedPublishedPlanID));
    };

    this.toggleTopicCallback = (topic) => {
      const { dispatch } = this.props;
      dispatch(toggleTopic(topic));
    };
  }

  componentDidMount() {
    this.fetchPublishedPlans();
  }

  render() {
    const {
      children,
      actionCallback,
      publishedPlans,
      selectedPublishedPlanID,
      shownUserID,
      selectedTopics,
      availableTopics,
    } = this.props;

    const items = [];

    let currentUserID = 0;
    let selectedUserID = null;
    let shownPlans = publishedPlans;
    let topicSelector = <></>;

    if (shownUserID > 0) {
      shownPlans = shownPlans.filter((p) =>
        planVisible(p, shownUserID, selectedPublishedPlanID, selectedTopics)
      );
      topicSelector = (
        <TopicSelector
          userName={`User ${shownUserID}`}
          selectedTopics={selectedTopics}
          availableTopics={availableTopics}
          toggleTopicCallback={this.toggleTopicCallback}
        />
      );
    }

    shownPlans.forEach((publishedPlan) => {
      if (currentUserID !== publishedPlan.userID) {
        items.push(
          <UserCaption
            key={`caption${publishedPlan.id}`}
            userID={publishedPlan.userID}
          />
        );
        currentUserID = publishedPlan.userID;
      }
      if (publishedPlan.id === selectedPublishedPlanID) {
        selectedUserID = publishedPlan.userID;

        items.push(
          <PlanPublishedExpanded
            key={publishedPlan.id}
            publishedPlan={publishedPlan}
            selectCallback={this.selectCallback}
          />
        );
      } else {
        const leftInteraction = (
          <StepTake
            published
            taken={planTaken(publishedPlan)}
            clickHandler={() => {}}
          />
        );
        const stepCount = planStepCount(publishedPlan);
        const rightInteraction = (
          <StyledButton
            onClick={() => this.selectCallback(publishedPlan.id)}
            type="button"
            className="icon"
          >
            <svg>
              <use
                style={{ flex: '0 0 auto', position: 'relative' }}
                xlinkHref="#icon-circle-published"
              />
            </svg>
            <StyledCount className="icon">
              {stepCount > 0 ? stepCount : ''}
            </StyledCount>
          </StyledButton>
        );
        items.push(
          <StyledPlanPublished
            key={publishedPlan.id}
            plan={publishedPlan}
            leftInteraction={leftInteraction}
            rightInteraction={rightInteraction}
          />
        );
      }
    });

    let OptionalInput = <></>;
    if (selectedPublishedPlanID) {
      const submitStepCallback = (stepText) => {
        actionCallback(
          stepProposalCreation(
            selectedPublishedPlanID,
            stepText,
            selectedUserID
          )
        );
      };

      const selectedPublishedPlan = findPlan(
        publishedPlans,
        selectedPublishedPlanID
      );

      // TODO: Find a way to preserve text when a plan vanishes due to owner unpublishing it. - jasper
      if (selectedPublishedPlan) {
        const placeholder = `Propose a step to “${truncateString(
          selectedPublishedPlan.name,
          32
        )}”!`;

        OptionalInput = (
          <StepInput
            stepTextOverride=""
            placeholderText={placeholder}
            submitStepCallback={submitStepCallback}
          />
        );
      }
    }

    return (
      <>
        {OptionalInput}
        <div id="content">
          <div>{items}</div>
        </div>
        {topicSelector}
        {children}
      </>
    );
  }
}

Stream.propTypes = {
  shownUserID: PropTypes.number.isRequired,
  selectedPublishedPlanID: PropTypes.string.isRequired,
  actionCallback: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  publishedPlans: PropTypes.arrayOf(PlanProps).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  publishedPlans: state.client.publishedPlans,
  shownUserID: state.client.shownUserID,
  selectedPublishedPlanID: state.client.selectedPublishedPlanID,
  selectedTopics: state.client.selectedTopics,
  availableTopics: state.client.availableTopics,
});

export default connect(mapStateToProps)(Stream);

// TODO Make rerenderign less hacky.
export const UserStream = connect(mapStateToProps)(Stream);

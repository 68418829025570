import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledMenuIconButton = styled.div`
  display: inline-block;
  line-height: 24px;
  padding: 0 8px;
  vertical-align: middle;
`;

const MenuIconButton = ({ identifier, onClick }) => (
  <StyledMenuIconButton onClick={onClick}>
    <svg height="24px" width="24px">
      { identifier ? <use xlinkHref={`#icon-${identifier}`} style={{ fill: '#fff' }} /> : <></> }
    </svg>
  </StyledMenuIconButton>
);

MenuIconButton.defaultProps = {
  onClick: () => {},
};

MenuIconButton.propTypes = {
  identifier: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default MenuIconButton;

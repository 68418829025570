import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Constants from '../constants';

import { StepProps } from '../utils/propTypes';

const StyledStep = styled.div`
  padding: 0 6px 8px 6px;

  .inner {
    background-color: #fff;
    border-radius: 16px;
    border: 1px solid white;
    color: ${({ colors }) => colors.dark};
    display: flex;
    min-width: 1px;
    padding: 6px;
  }

  .text {
    flex: 1 1 auto;
    padding: 4px 6px 0 6px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .right {
    flex: 0 0 auto;
    position: relative;
  }

  button {
    height: 24px;
    fill: ${({ colors }) => colors.dark};
    stroke: ${({ colors }) => colors.normal};
    width: 24px;

    svg {
      height: 24px;
      width: 24px;
    }
  }
`;

const customizedStep = (colors) => ({
  step,
  leftInteraction,
  rightInteraction,
}) => (
  <StyledStep id={step.id} colors={colors}>
    <div className="inner">
      {leftInteraction}
      <div className="text">{step.text}</div>
      {rightInteraction}
    </div>
  </StyledStep>
);

export const StepPublished = customizedStep({
  light: Constants.PublishedLight,
  normal: Constants.PublishedNormal,
  dark: Constants.PublishedDark,
});
export const StepTaken = customizedStep({
  light: Constants.TakenLight,
  normal: Constants.TakenNormal,
  dark: Constants.TakenDark,
});
export const StepUntaken = customizedStep({
  light: Constants.UntakenLight,
  normal: Constants.UntakenNormal,
  dark: Constants.UntakenDark,
});
export const StepProposed = customizedStep({
  light: Constants.ProposedLight,
  normal: Constants.ProposedNormal,
  dark: Constants.ProposedDark,
});

const propTypes = {
  step: StepProps.isRequired,
  leftInteraction: PropTypes.node.isRequired,
  rightInteraction: PropTypes.node.isRequired,
};

StepPublished.propTypes = propTypes;
StepTaken.propTypes = propTypes;
StepUntaken.propTypes = propTypes;
StepProposed.propTypes = propTypes;

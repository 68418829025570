import Dexie from 'dexie';
import 'dexie-observable';

export const updateActionsDB = async () => {
  const actionsDB = new Dexie('actions');
  actionsDB.version(4).stores({ actions: '++id, &aid, new, type' });
  actionsDB.version(3).stores({ actions: '++id, &aid, new, *type' }).upgrade((a) => a);
  actionsDB.version(2).stores({ actions: '++id, &aid, new' }).upgrade((a) => a);
  actionsDB.version(1).stores({ actions: '++id, &aid' });
  await actionsDB.actions.get(1);
};

export const actionsDBConnection = () => {
  const actionsDB = new Dexie('actions');
  actionsDB.version(4).stores({ actions: '++id, &aid, new, type' });
  actionsDB.open().catch((e) => {
    console.error(`Open failed: ${e.stack}`);
  });
  return actionsDB;
};

export const configDBConnection = () => {
  const configDB = new Dexie('config');
  configDB.version(1).stores({ values: '&key' });
  configDB.open().catch((e) => {
    console.error(`Open failed: ${e.stack}`);
  });
  return configDB;
};

export const actionCreationListener = (callback, ignoreFirst = 0) => {
  actionsDBConnection().on('changes', (changes) => {
    changes.forEach((change) => {
      if (change.key <= ignoreFirst) {
        return;
      }
      switch (change.type) {
        case 1: {
          if (Array.isArray(change.obj)) {
            change.obj.forEach((obj) => callback(obj));
          } else {
            callback(change.obj);
          }
          break;
        }
        default:
          break;
      }
    });
  });
};

export const configCreationOrUpdateListener = (configDB, callback) => {
  configDB.on('changes', (changes) => {
    changes.forEach((change) => {
      switch (change.type) {
        case 1: {
          callback(change.obj);
          break;
        }
        case 2: {
          callback(change.obj);
          break;
        }
        default:
          break;
      }
    });
  });
};

import updateObject from '../utils/updateObject';

import {
  PUBLISHED_PLANS_OVERRIDE,
  PUBLISHED_PLAN_SELECTION,
  USER_SELECTION,
  SIGN_IN,
  SIGN_OUT,
  REVERTABLE_ACTIONS,
  TOGGLE_TOPIC,
  TOPICS_OVERRIDE,
} from './actions';

const defaultState = {
  isSignedIn: false,
  shownUserID: 0,
  selectedPublishedPlanID: '',
  publishedPlans: [],
  lastAction: {},
  selectedTopics: [],
  availableTopics: [],
};

const toggleTopic = (selectedTopics, topic) => {
  const i = selectedTopics.indexOf(topic);
  const newSelectedTopics = selectedTopics.slice();
  i > -1 ? newSelectedTopics.splice(i, 1) : newSelectedTopics.push(topic);
  return newSelectedTopics;
};

export default (initialState = defaultState, action) => {
  let state = initialState;
  if (REVERTABLE_ACTIONS.includes(action.type)) {
    state = updateObject(state, { lastAction: action });
  }
  switch (action.type) {
    case PUBLISHED_PLANS_OVERRIDE:
      return updateObject(state, {
        publishedPlans: action.payload.publishedPlans,
      });
    case PUBLISHED_PLAN_SELECTION:
      return updateObject(state, {
        selectedPublishedPlanID: action.payload.selectedPublishedPlanID,
      });
    case SIGN_IN: {
      return updateObject(state, { isSignedIn: true });
    }
    case SIGN_OUT: {
      return updateObject(state, { isSignedIn: false });
    }
    case USER_SELECTION:
      return updateObject(state, { shownUserID: action.payload.shownUserID });
    case TOGGLE_TOPIC:
      return updateObject(state, {
        selectedTopics: toggleTopic(state.selectedTopics, action.payload.topic),
      });
    case TOPICS_OVERRIDE:
      return updateObject(state, {
        availableTopics: action.payload.topics,
        selectedTopics: [],
      });
    default:
      return state;
  }
};

import Dexie from 'dexie';
import {
  configDBConnection,
  actionsDBConnection,
} from './db';
import {
  plansClearing, signIn, signOut, publishedPlansOverride,
} from '../redux/actions';
import { post } from './fetch';

const postInterval = 10000;

const state = {
  csrfToken: '',
  isSignedIn: '',
  lastReceivedAID: '',
  lastPostedID: 0,
  lastPostTime: 0,
  postActionsIntervalID: 0,
  fetchActionsTimeout: 0,
  actionCache: [],
};

const configDB = configDBConnection();
const actionsDB = actionsDBConnection();

const resetState = () => {
  clearInterval(state.postActionsIntervalID);
  clearTimeout(state.fetchActionsTimeout);
  state.csrfToken = '';
  state.isSignedIn = false;
  state.lastReceivedAID = '';
  state.lastPostedID = 0;
  state.lastPostTime = 0;
  state.postActionsIntervalID = 0;
  state.fetchActionsTimeout = 0;
  state.actionCache = [];
};

const getSignOut = () => {
  fetch('/users/sign_out');
};

const doSignOut = (store) => {
  getSignOut();
  resetState();
  actionsDB.actions.clear();
  configDB.values.clear();
  store.dispatch(plansClearing());
  store.dispatch(signOut());
  console.log('signed out');
};

const putConfig = (key, value) => {
  configDB.values.put({ key, value });
  state[key] = value;
};

export const postActions = (store, actions, lastKey) => {
  state.lastPostTime = new Date().getTime();

  if (actions.length > 0 && state.isSignedIn) {
    console.log('posting', state.csrfToken);
    post('/actions', state.csrfToken, { actions }).then((response) => {
      if (response.status === 204) {
        putConfig('lastPostedID', lastKey || actions[actions.length - 1].id);
        state.actionCache = [];
        console.log(`posted ${actions.length} actions until #${state.lastPostedID}`);
      } else if (response.status === 422) {
        // probably wrong CSRF token
        console.log('reloading');
        location.reload();
      } else {
        doSignOut(store);
      }
    }).catch((error) => {
      console.log(error); // ignore for now
    });
  }
};

const fetchActions = (store) => {
  const sendState = store.getState().client;
  if (!state.isSignedIn) return;

  fetch(
    `/actions?after_aid=${state.lastReceivedAID}`
      + `&shown_user_id=${sendState.shownUserID}`
      + `&published_plan_id=${sendState.selectedPublishedPlanID}`,
    {
      headers: {
        'content-type': 'application/json; charset=UTF-8',
        'X-CSRF-TOKEN': state.csrfToken,
      },
      credentials: 'same-origin',
    },
  ).then((result) => {
    if (result.status === 200) {
      state.fetchActionsTimeout = setTimeout(() => fetchActions(store), 200);
      return result.json();
    }
    if (result.status === 502) {
      state.fetchActionsTimeout = setTimeout(() => fetchActions(store), 5000);
      return { error: 'probably rebooting' };
    }
    doSignOut(store);
    return { error: 'unknown server error' };
  }).then((jsn) => {
    if (jsn.error) return;
    const receiveState = store.getState().client;
    if (jsn.publishedPlans.length > 0 && sendState.shownUserID === receiveState.shownUserID) {
      store.dispatch(publishedPlansOverride(jsn.publishedPlans));
    }
    const len = jsn.actions.length;
    if (len > 0) {
      putConfig('lastReceivedAID', jsn.actions[len - 1].aid);
      actionsDB.actions.bulkAdd(jsn.actions).then((lastKey) => {
        console.log(`last key: ${lastKey}`);
      }).catch(Dexie.BulkError, (e) => {
        console.log(`${e.failures.length} actions not added.`, e, jsn.actions.toString());
      });
    }
  }).catch((error) => {
    // connection error, just skip.
    console.log(error);
    state.fetchActionsTimeout = setTimeout(() => fetchActions(store), 5000);
  });
};

export default (store, isSignedIn, csrfToken) => {
  state.isSignedIn = isSignedIn;
  state.csrfToken = csrfToken;

  const postActionsLoop = () => {
    if (state.lastPostTime > new Date().getTime() - postInterval) return;
    actionsDB.actions.where(':id').above(state.lastPostedID).sortBy('id', (actions) => {
      postActions(store, actions.filter((a) => a.new).concat(state.actionCache));
    });
  };

  configDB.values.toArray((pairs) => {
    pairs.forEach((pair) => {
      state[pair.key] = pair.value;
    });
  });

  configDB.values.get('wasSignedIn', (value) => {
    const wasSignedIn = value && value.value;
    if (state.isSignedIn && wasSignedIn) {
      console.log('already signed in');
      store.dispatch(signIn());
      fetchActions(store);
      state.postActionsIntervalID = setInterval(postActionsLoop, postInterval);
    } else if (state.isSignedIn && !wasSignedIn) {
      console.log('signing in');
      actionsDB.actions.toArray((actions) => {
        state.actionCache = state.actionCache.concat(actions);
        store.dispatch(plansClearing());
        actionsDB.actions.clear();
        store.dispatch(signIn());
        putConfig('wasSignedIn', true);
        console.log('signed in');
        postActionsLoop();
        state.postActionsIntervalID = setInterval(postActionsLoop, postInterval);
        fetchActions(store);
      });
    } else if (!state.isSignedIn && wasSignedIn) {
      doSignOut(store);
    } else {
      console.log('anon mode');
    }
  });
};

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

const StyledTopNav = styled.div`
  background-color: #969696;
  color: #fff;
  font-family: monospace;
  font-size: 24px;
  height: 40px;
  line-height: 40px;
  padding-right: 8px;

  a {
    color: #fff;
    text-decoration: none;
  }
`;

const StyledLeft = styled.div`
  color: #fff;
  display: inline-block;
  text-align: left;
  text-decoration: none;
  width: 47%;
`;

const StyledMiddle = styled.div`
  display: inline-block;
  text-align: center;
  width: 10%;
`;

const StyledRight = styled.div`
  display: inline-block;
  text-align: right;
  width: 43%;
`;

const StyledName = styled.span`
  font-size: 20px;
  font-family: sans-serif;
`;

const TopNav = ({ left, middle, right }) => {
  if (!right) {
    const { shownUserID } = useParams();
    if (shownUserID) {
      right = <StyledName>{`User ${shownUserID}`}</StyledName>;
    }
  }

  return (
    <StyledTopNav>
      <StyledLeft>{left}</StyledLeft>
      <StyledMiddle>{middle}</StyledMiddle>
      <StyledRight>{right}</StyledRight>
    </StyledTopNav>
  );
};

TopNav.propTypes = {
  left: PropTypes.element.isRequired,
  middle: PropTypes.element.isRequired,
  right: PropTypes.element,
};

export default TopNav;

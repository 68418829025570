import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { planTaken } from '../../utils/planHelpers';
import { PlanProps } from '../../utils/propTypes';

function formatBalance(balance) {
  if (balance === 0) return '0000';
  let s = `${balance}`;
  while (s.length < 5) {
    s = s.replace('-', '-0');
  }
  return s;
}

const Balance = ({ plans }) => {
  let balance = 0;
  plans.forEach((plan) => {
    if (!planTaken(plan)) {
      balance -= 1;
      plan.steps.forEach((step) => {
        if (!step.takenAt && !(step.proposedAt && !step.acceptedAt)) {
          balance -= 1;
        }
      });
    }
  });
  return <>{formatBalance(balance)}</>;
};

Balance.propTypes = {
  plans: PropTypes.arrayOf(PlanProps).isRequired,
};


const mapStateToProps = (state) => ({
  plans: state.plans,
});

export default connect(
  mapStateToProps,
)(Balance);

import { stepCreation } from '../redux/actions';

export default () => {
  const welcome = stepCreation(
    '',
    'Welcome to Steps! Until you create an account, everything is saved only in your browser, '
    + 'so data might easily be lost. (Delete by swiping left.)',
  );
  const firstPlan = stepCreation('', 'These are your plans.');
  const tutorialCreation = stepCreation('', 'Select this plan by clicking the circle with the number →');
  const moreInfo = stepCreation('', 'If you create an account, you can… →');
  const sendFeedback = stepCreation('', 'Send me any feedback via jasper@wemakesteps.com.');
  const thankYou = stepCreation('', 'Thank you!');
  return [
    welcome,
    firstPlan,
    stepCreation(firstPlan.aid, 'This is a step from the first plan.'),
    tutorialCreation,
    stepCreation(tutorialCreation.aid, 'These are the plan\'s steps.'),
    stepCreation(tutorialCreation.aid, 'Use the text field below to add another step.'),
    stepCreation(tutorialCreation.aid, 'Delete this step by swiping it left.'),
    stepCreation(tutorialCreation.aid, 'Extract this step into it\'s own plan by clicking the minus →'),
    stepCreation(tutorialCreation.aid, 'Add other plans to this one by clicking their plus (+).'),
    stepCreation(tutorialCreation.aid, 'Now deselect this plan and start your own!'),
    moreInfo,
    stepCreation(moreInfo.aid, '…back up plans and sync them between your devices.'),
    stepCreation(moreInfo.aid, '…publish plans/steps by swiping them right.'),
    stepCreation(moreInfo.aid, '…interact in the public space. (Click the globe above.)'),
    stepCreation(moreInfo.aid, '…do much more that is still being developed.'),
    sendFeedback,
    thankYou,
  ];
};

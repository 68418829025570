import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MenuIconButton from '../../components/MenuIconButton';

const StyledSideNav = styled.div`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 10;

  .background{
    background-color: black;
    height: 100%;
    opacity: 0.25;
    position: fixed;
    width: 100%;
  }

  .foreground{
    background-color: #969696;
    font-family: sans-serif;
    font-size: 24px;
    height: 100%;
    line-height: 40px;
    max-width: 400px;
    position: fixed;
    width: 80%;

    p {
      color: white;
      margin: 0;
      padding: 0;
      padding-left: 40px;
    }

    a {
      color: white;
      display: block;

      &.session {
        padding-left: 40px;
      }

      &.small {
        font-size: 14px;
        line-height: 24px;
        padding-left: 40px;
      }
    }
  }
`;

const SideNav = ({
  toggleSideNav, sideNavIsShown, isSignedIn,
}) => (
  <StyledSideNav
    visible={sideNavIsShown}
    onClick={toggleSideNav}
  >
    <div className="background" />
    <div className="foreground">
      <p>
        &nbsp;
      </p>
      <Link to="/">
        <MenuIconButton identifier="home" />
        Your plans
      </Link>
      <Link to="/stream">
        <MenuIconButton identifier="public" />
        Published
      </Link>
      <br />
      {isSignedIn
        ? <a className="session" href="/users/sign_out">Sign out</a>
        : (
          <>
            <a className="session" href="/users/sign_in">Sign in</a>
            <a className="session" href="/users/sign_up">Sign up</a>
          </>
        )}
      <br />
      <a className="small" href="/terms_of_use">Terms of Use</a>
      <a className="small" href="/privacy_notice">Privacy Notice</a>
      <a className="small" href="/imprint">Imprint</a>
    </div>
  </StyledSideNav>
);

SideNav.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  sideNavIsShown: PropTypes.bool.isRequired,
  toggleSideNav: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isSignedIn: state.client.isSignedIn,
});

export default connect(
  mapStateToProps,
)(SideNav);

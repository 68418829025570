import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledCaption = styled.div`
  color: #969696;
  font-size: 14px;
  padding-left: 16px;
  padding-top: 8px;

  a {
    color: #969696;
  }
`;

const UserCaption = ({ userID }) => (
  <StyledCaption>
    <Link to={`/${userID}`}>
      {`User ${userID}`}
    </Link>
  </StyledCaption>
);

UserCaption.propTypes = {
  userID: PropTypes.number.isRequired,
};

export default UserCaption;

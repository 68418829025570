import { createStore, combineReducers } from 'redux';
import {
  actionsDBConnection,
  actionCreationListener,
  updateActionsDB,
} from '../utils/db';
import { plansReducer } from './plansReducer';
import clientStateReducer from './clientStateReducer';
import { ACTION_REVERSION } from './actions';

const prehydrate = async () => {
  const actionsDB = actionsDBConnection();

  const ignoredActions = [];
  await actionsDB.actions.where('type').equals(ACTION_REVERSION).reverse().each((action) => {
    if (!ignoredActions.includes(action.aid)) {
      ignoredActions.push(action.payload.revertedAID);
      ignoredActions.push(action.aid);
    }
  });

  let plansPrehydrated = [];
  await actionsDB.actions.each((action) => {
    if (!ignoredActions.includes(action.aid)) {
      plansPrehydrated = plansReducer(plansPrehydrated, action);
    }
  });

  return plansPrehydrated;
};

export default async () => {
  await updateActionsDB();

  const store = createStore(
    combineReducers({
      plans: plansReducer,
      client: clientStateReducer,
    }),
    {
      plans: await prehydrate(),
    },
    (typeof window !== 'undefined')
      ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
      : undefined,
  );

  actionCreationListener(
    (action) => {
      if (action.type === ACTION_REVERSION) {
        prehydrate().then((plansPrehydrated) => {
          action.payload.hydrated = plansPrehydrated;
          store.dispatch(action);
        });
      } else {
        store.dispatch(action);
      }
    },
  );

  return store;
};

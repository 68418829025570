import React from 'react';
import PropTypes from 'prop-types';
import {
  planExtraction,
  stepAcceptance,
  stepAcceptanceNotificationCreation,
  stepDeletion,
  stepDenialNotificationCreation,
  stepPublication,
  stepTaking,
  stepUnpublication,
} from '../redux/actions';
import { PlanProps, StepProps } from '../utils/propTypes';
import { StepPublished, StepTaken, StepUntaken, StepProposed } from './steps';
import Swipable from './Swipable';
import StepIconButton from './StepIconButton';
import * as Constants from '../constants';

class Step extends React.Component {
  render() {
    const { plan, step, actionCallback, proposeStepTextCallback } = this.props;

    const leftInteractionHandler = step.takenAt
      ? () => {}
      : () => {
          if (step.proposedAt && !step.acceptedAt) {
            actionCallback(
              stepAcceptance(plan.id, step.id),
              stepAcceptanceNotificationCreation(
                plan.id,
                step.id,
                step.text,
                step.fromUserID
              )
            );
          } else {
            actionCallback(stepTaking(plan.id, step.id));
          }
        };

    const leftInteraction = (
      <StepIconButton
        onClick={leftInteractionHandler}
        icon={step.takenAt ? 'check' : 'check-outline'}
      />
    );

    const rightInteractionHandler =
      step.proposedAt && !step.acceptedAt
        ? () => {
            actionCallback(
              stepDeletion(plan.id, step.id),
              stepDenialNotificationCreation(
                plan.id,
                step.id,
                step.text,
                step.fromUserID
              )
            );
          }
        : () => actionCallback(planExtraction(plan.id, step.id));

    const rightInteraction = (
      <StepIconButton
        onClick={rightInteractionHandler}
        icon={
          step.proposedAt && !step.acceptedAt ? 'circle-close' : 'circle-minus'
        }
      />
    );

    const swipeHandlers = {};
    swipeHandlers.right = step.publishedAt
      ? null
      : {
          handler: () => actionCallback(stepPublication(plan.id, step.id)),
          color: 'white',
          label: 'Publish',
        };
    swipeHandlers.left = step.publishedAt
      ? {
          handler: () => actionCallback(stepUnpublication(plan.id, step.id)),
          color: 'white',
          label: 'Unpublish',
        }
      : {
          handler: () => actionCallback(stepDeletion(plan.id, step.id)),
          color: 'white',
          label: 'Delete',
        };
    swipeHandlers.doubletap = () => proposeStepTextCallback(step.text);

    let Container = StepUntaken;

    if (step.proposedAt && !step.acceptedAt) {
      Container = StepProposed;
    } else if (step.publishedAt) {
      Container = StepPublished;
    } else if (step.takenAt) {
      Container = StepTaken;
    }

    if (step.proposedAt && !step.acceptedAt) {
      return (
        <Container
          step={step}
          leftInteraction={leftInteraction}
          rightInteraction={rightInteraction}
        />
      );
    }

    return (
      <Swipable swipeHandlers={swipeHandlers}>
        <Container
          step={step}
          leftInteraction={leftInteraction}
          rightInteraction={rightInteraction}
        />
      </Swipable>
    );
  }
}

Step.propTypes = {
  actionCallback: PropTypes.func.isRequired,
  proposeStepTextCallback: PropTypes.func.isRequired,
  plan: PlanProps.isRequired,
  step: StepProps.isRequired,
};

export default Step;

import React from 'react';
import Hammer from 'hammerjs';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SwipeHandlers } from '../utils/propTypes';
import * as Constants from '../constants';

const Icon = styled.div`
  display: inline-block;
  vertical-align: top;
`;
const Container = styled.div`
  display: flex;
`;
const Behind = styled.div`
  font-size: 1.2em;
  font-weight: 700;
  width: 0;
`;
const BehindInner = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 8px 20px 0 16px;
  width: 98vw;
`;
const BehindItem = styled.div`
  color: ${({ color }) => color || 'white'};
  fill: ${({ color }) => color || 'white'};
`;
const Foreground = styled.div`
  width: 100%;
`;

const initSwipeRight = (swipeHandlers, elem, hammer) => {
  if (swipeHandlers.right) {
    hammer.on('swiperight', swipeHandlers.right.handler);
    hammer.on('panmove', (ev) => {
      elem.style.transform = `translate(${ev.deltaX}px,0)`;
    });
  } else {
    hammer.on('panmove', (ev) => {
      if (ev.deltaX < 0) elem.style.transform = `translate(${ev.deltaX}px,0)`;
    });
  }
};

class Swipable extends React.Component {
  componentDidMount() {
    const { swipeHandlers } = this.props;
    const elem = this._elem;
    this.hammer = Hammer(elem);
    this.hammer.on('doubletap', swipeHandlers.doubletap);
    this.hammer.on('hammer.input', (ev) => {
      if (ev.isFinal) {
        elem.style.transform = 'translate(0,0)';
      }
    });
    this.hammer.on('swipeleft', swipeHandlers.left.handler);
    initSwipeRight(swipeHandlers, elem, this.hammer);
  }

  componentDidUpdate() {
    const { swipeHandlers } = this.props;
    this.hammer.off('doubletap');
    this.hammer.off('swipeleft');
    this.hammer.off('swiperight');

    this.hammer.on('doubletap', swipeHandlers.doubletap);
    this.hammer.on('swipeleft', swipeHandlers.left.handler);
    initSwipeRight(swipeHandlers, this._elem, this.hammer);
  }

  render() {
    const { children, swipeHandlers } = this.props;
    return (
      <Container>
        <Behind>
          <BehindInner>
            {swipeHandlers.right ? (
              <BehindItem color={swipeHandlers.right.color}>
                {swipeHandlers.right.label}
                <Icon className="icon">
                  <svg>
                    <use xlinkHref="#icon-double-arrow" />
                  </svg>
                </Icon>
              </BehindItem>
            ) : (
              <BehindItem />
            )}
            <BehindItem color={swipeHandlers.left.color}>
              <Icon className="icon">
                <svg>
                  <use xlinkHref="#icon-double-arrow-reversed" />
                </svg>
              </Icon>
              {swipeHandlers.left.label}
            </BehindItem>
          </BehindInner>
        </Behind>
        <Foreground
          ref={(el) => {
            this._elem = el;
          }}
        >
          {children}
        </Foreground>
      </Container>
    );
  }
}

Swipable.propTypes = {
  swipeHandlers: SwipeHandlers.isRequired,
  children: PropTypes.element.isRequired,
};

export default Swipable;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import TabScope from '../containers/TabScope';
import initStore from '../redux/initStore';
import '../styles/spa';
import syncer, { postActions } from '../utils/syncer';
import TopNav from '../components/menu/TopNav';
import Stream, { UserStream } from '../containers/Stream';
import Snackbar from '../containers/Snackbar';
import HomeLink from '../components/menu/HomeLink';
import StreamLink from '../components/menu/StreamLink';
import Balance from '../containers/menu/Balance';
import SideNavOpener from '../components/menu/SideNavOpener';
import SideNav from '../containers/menu/SideNav';
import { actionsDBConnection } from '../utils/db';
import { userSelection, authorizeActions } from '../redux/actions';

const HistoryListener = withRouter(
  (props) => {
    const { history, children, store } = props;
    const locationHandler = (location) => {
      switch (location.pathname) {
        case '/':
        case '/stream':
          store.dispatch(userSelection(0));
          break;
        default:
          store.dispatch(userSelection(location.pathname.match(/^\/(\d+)/)[1]));
          break;
      }
    };
    locationHandler(history.location);
    history.listen(locationHandler);
    return <>{children}</>;
  },
);

class App extends React.Component {
  constructor() {
    super();
    this.state = { sideNavIsShown: false };
  }

  render() {
    const { isSignedIn, store } = this.props;

    const toggleSideNav = () => {
      const { sideNavIsShown } = this.state;
      this.setState({ sideNavIsShown: !sideNavIsShown });
    };
    const actionsDB = actionsDBConnection();
    const actionCallback = (...actions) => {
      actionsDB.actions.bulkAdd(
        authorizeActions(actions, isSignedIn),
      ).then((lastKey) => postActions(store, actions, lastKey));
    };

    const { sideNavIsShown } = this.state;

    return (
      <Provider store={store}>
        <Router>
          <HistoryListener store={store}>
            <SideNav toggleSideNav={toggleSideNav} sideNavIsShown={sideNavIsShown} />
            <Snackbar actionCallback={actionCallback} />
            <Switch>
              <Route path="/stream">
                <Stream actionCallback={actionCallback}>
                  <TopNav
                    left={<SideNavOpener toggleSideNav={toggleSideNav} />}
                    middle={<HomeLink />}
                  />
                </Stream>
              </Route>
              <Route path="/:shownUserID">
                <UserStream actionCallback={actionCallback}>
                  <TopNav
                    left={<SideNavOpener toggleSideNav={toggleSideNav} />}
                    middle={<HomeLink />}
                  />
                </UserStream>
              </Route>
              <Route path="/">
                <TabScope actionCallback={actionCallback} />
                <TopNav
                  left={<SideNavOpener toggleSideNav={toggleSideNav} />}
                  middle={<StreamLink />}
                  right={<Balance />}
                />
              </Route>
            </Switch>
          </HistoryListener>
        </Router>
      </Provider>
    );
  }
}

App.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  store: PropTypes.object.isRequired,
};

document.addEventListener('DOMContentLoaded', () => {
  const isSignedIn = document.querySelector('body').dataset.signedin === 'true';
  const csrfToken = document.querySelector('[name=csrf-token]').content;

  initStore().then((store) => {
    ReactDOM.render(<App isSignedIn={isSignedIn} store={store} />,
      document.body.appendChild(document.createElement('section')));
    syncer(store, isSignedIn, csrfToken);

    // fix for orientation change on mobile
    window.onresize = () => {
      document.getElementsByTagName('section')[0].style.height = `${
        window.innerHeight
      }px`;
    };
    window.onresize();
  });
});

import React from 'react';
import PropTypes from 'prop-types';
import hasTouchScreen from '../utils/hasTouchScreen';
import styles from '../styles/StepInput';

const placeCaretAtEnd = (el) => {
  el.focus();
  if (typeof window.getSelection !== 'undefined'
            && typeof document.createRange !== 'undefined') {
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange !== 'undefined') {
    const textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
};

class StepInput extends React.Component {
  constructor() {
    super();
    this.state = {
      stepText: '',
    };
    this.overrideStepText = (text) => {
      const short = text.substring(0, 280);
      this.setState({ stepText: short });
      const input = document.getElementById('resettable-step-text-input');
      if (input.innerText === short) {
        return;
      }
      input.innerText = short;
      placeCaretAtEnd(input);
    };
  }

  render() {
    const {
      placeholderText,
      stepTextOverride,
      submitStepCallback,
    } = this.props;
    const {
      stepText,
    } = this.state;
    if (stepTextOverride !== '' && stepText === '') {
      this.overrideStepText(stepTextOverride);
    }
    const submitHandler = () => {
      if (stepText === '') return;
      submitStepCallback(stepText);
      this.overrideStepText('');
    };
    const inputHandler = (e) => {
      this.overrideStepText(e.target.innerText);
      e.preventDefault();
    };
    const preventDefault = (e) => {
      e.preventDefault();
    };
    const keyHandler = (e) => {
      if (!e.shiftKey && (e.keyCode === 13 || e.which === 13)) {
        submitHandler();
        e.stopPropagation();
        e.preventDefault();
      }
    };
    const btn = stepText.trim() !== '' ? (
      <button type="button" name="submit_step_text" onClick={submitHandler} className={styles.submit}>
        <svg height="36px" width="36px">
          <circle cx="18px" cy="18px" r="18px" className={styles.background} />
          <use xlinkHref="#icon-submit" className={styles.foreground} />
        </svg>
      </button>
    ) : (
      <button disabled type="button" name="submit_step_text" className={styles.submit}>
        <svg height="36px" width="36px">
          <circle
            cx="18px"
            cy="18px"
            r="18px"
            className={styles.backgroundDisabled}
          />
          <use xlinkHref="#icon-submit" className={styles.foreground} />
        </svg>
      </button>
    );
    return (
      <footer>
        <div
          ref={(el) => { (this._elem = el); }}
          onBlur={hasTouchScreen() ? null : () => this._elem.focus()}
          className={styles.input}
          type="text"
          onChange={preventDefault}
          onInput={inputHandler}
          onKeyDown={keyHandler}
          contentEditable
          id="resettable-step-text-input"
          data-placeholder={placeholderText}
        />
        {btn}
      </footer>
    );
  }
}

StepInput.propTypes = {
  placeholderText: PropTypes.string.isRequired,
  submitStepCallback: PropTypes.func.isRequired,
  stepTextOverride: PropTypes.string.isRequired,
};

export default StepInput;

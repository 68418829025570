import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Plans from './Plans';
import StepInput from '../components/StepInput';
import scrollTop from '../utils/scrollTop';
import { SCROLLABLE_ACTIONS, stepCreation } from '../redux/actions';
import { PlanProps } from '../utils/propTypes';
import { findPlan, truncateString } from '../utils/planHelpers';

let scrollTimeout;

class TabScope extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlanID: '',
      proposedStepText: '',
    };

    const { actionCallback } = props;

    this.planSelectionCallback = (planID) => {
      this.setState({ selectedPlanID: planID });
    };
    this.planDeselectionCallback = () => {
      this.setState({ selectedPlanID: '' });
    };
    this.proposeStepTextCallback = (text) => {
      this.setState({ proposedStepText: text });
    };
    const scrollToPlan = (planID) => {
      window.clearTimeout(scrollTimeout);
      if (planID !== '') {
        scrollTimeout = window.setTimeout(() => {
          // console.log(`scrolling to ${planID}`);
          const outer = document.getElementById('content');
          const inner = document.getElementById(planID);
          if (outer && inner) scrollTop(outer, inner);
        }, 1000);
      }
    };
    this.scrollingActionCallback = (...actions) => {
      if (SCROLLABLE_ACTIONS.includes(actions[0].type)) {
        scrollToPlan(actions[0].payload.planID || actions[0].aid);
      }
      actionCallback(...actions);
    };
    this.submitStepCallback = (text) => {
      const { selectedPlanID } = this.state;
      this.scrollingActionCallback(stepCreation(selectedPlanID, text));
    };
  }

  render() {
    const { selectedPlanID, proposedStepText } = this.state;
    let placeholder = "What's the plan?";
    if (selectedPlanID) {
      const { plans } = this.props;
      const selectedPlan = findPlan(plans, selectedPlanID);
      if (!selectedPlan) {
        this.planDeselectionCallback();
      }
      placeholder = `Add a step to “${truncateString(selectedPlan.name, 32)}”!`;
    }

    const element = (
      <>
        <StepInput
          stepTextOverride={proposedStepText}
          placeholderText={placeholder}
          submitStepCallback={this.submitStepCallback}
        />
        <div id="content">
          <Plans
            actionCallback={this.scrollingActionCallback}
            proposeStepTextCallback={this.proposeStepTextCallback}
            planDeselectionCallback={this.planDeselectionCallback}
            planSelectionCallback={this.planSelectionCallback}
            selectedPlanID={selectedPlanID}
          />
        </div>
      </>
    );

    // TODO: fix state transition warning
    if (proposedStepText !== '') {
      this.setState({ proposedStepText: '' });
    }
    return element;
  }
}

TabScope.propTypes = {
  actionCallback: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(PlanProps).isRequired,
};

const mapStateToProps = (state) => ({
  plans: state.plans,
});

export default connect(mapStateToProps)(TabScope);

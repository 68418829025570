import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { ActionProps } from '../utils/propTypes';
import { ACTION_REVERSION, actionReversion, REVERTABLE_LABELS } from '../redux/actions';

const StyledSnackbar = styled.div`
  -webkit-animation: cssAnimation 5s forwards;
  animation: cssAnimation 8s forwards;
  background-color: #666666;
  border-radius: 4px;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 50%;
  margin-left: -160px;
  padding: 16px;
  position: fixed;
  top: 57px;
  width: 288px;
  z-index: 5;

  a {
    color: #e69120;
    cursor: pointer;
  }

  @keyframes cssAnimation {
      0%   {opacity: 1;}
      95%  {opacity: 1;}
      100% {opacity: 0; visibility: hidden;}
  }

  @-webkit-keyframes cssAnimation {
      0%   {opacity: 1;}
      95%  {opacity: 1;}
      100% {opacity: 0; display: none;}
  }
`;


const Snackbar = ({ lastAction, actionCallback }) => {
  if (!lastAction.new) {
    return <></>;
  }

  return (
    <StyledSnackbar key={lastAction.aid}>
      {REVERTABLE_LABELS[lastAction.type]}
      <a onClick={() => actionCallback(actionReversion(lastAction.aid))}>REVERT</a>
    </StyledSnackbar>
  );
};

Snackbar.defaultProps = {
  lastAction: {
    aid: '',
    type: ACTION_REVERSION,
  },
};

Snackbar.propTypes = {
  lastAction: ActionProps,
  actionCallback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  lastAction: state.client.lastAction,
});

export default connect(
  mapStateToProps,
)(Snackbar);

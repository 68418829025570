import updateObject from '../utils/updateObject';
import { planSimple, planTaken } from '../utils/planHelpers';

import {
  HYDRATION,
  ACTION_REVERSION,
  PLAN_DELETION,
  PLAN_EXTRACTION,
  PLAN_JOIN,
  PLAN_PUBLICATION,
  PLAN_TAKING,
  PLAN_UNPUBLICATION,
  PLANS_CLEARING,
  STEP_ACCEPTANCE,
  STEP_ACCEPTANCE_NOTIFICATION,
  STEP_CREATION,
  STEP_DELETION,
  STEP_DENIAL_NOTIFICATION,
  STEP_PROPOSAL,
  STEP_PROPOSAL_NOTIFICATION,
  STEP_PUBLICATION,
  STEP_TAKING,
  STEP_UNPUBLICATION,
} from './actions';

const tagRE = /#(\w*[a-zA-Z]\w*)(?:\W|$)/g;

const extractTopics = (text) => {
  return [...text.matchAll(tagRE)].map((match) => match[1]);
};

const buildStep = (stepID, stepText, createdAt) => ({
  id: stepID,
  createdAt,
  text: stepText,
  topics: extractTopics(stepText),
});

const buildPlan = (planID, planName, createdAt) => ({
  id: planID,
  name: planName,
  createdAt,
  steps: [],
  topics: extractTopics(planName),
});

const buildNotificationPlan = (planID, planName, createdAt) => {
  const newPlan = buildPlan(planID, planName, createdAt);
  newPlan.isNotificationPlan = true;
  return newPlan;
};

const planToStep = (plan) => ({
  id: plan.id,
  text: plan.name,
  createdAt: plan.createdAt,
  topics: plan.topics,
  ...(plan.firstPublishedAt ? { firstPublishedAt: plan.firstPublishedAt } : {}),
  ...(plan.publishedAt ? { publishedAt: plan.publishedAt } : {}),
  ...(plan.takenAt ? { takenAt: plan.takenAt } : {}),
  ...(plan.fromUserID ? { fromUserID: plan.fromUserID } : {}),
  ...(plan.proposedAt ? { proposedAt: plan.proposedAt } : {}),
  ...(plan.acceptedAt ? { acceptedAt: plan.acceptedAt } : {}),
});

const stepToPlan = (step) => ({
  id: step.id,
  name: step.text,
  createdAt: step.createdAt,
  steps: [],
  topics: step.topics,
  ...(step.firstPublishedAt ? { firstPublishedAt: step.firstPublishedAt } : {}),
  ...(step.publishedAt ? { publishedAt: step.publishedAt } : {}),
  ...(step.takenAt ? { takenAt: step.takenAt } : {}),
  ...(step.fromUserID ? { fromUserID: step.fromUserID } : {}),
  ...(step.proposedAt ? { proposedAt: step.proposedAt } : {}),
  ...(step.acceptedAt ? { acceptedAt: step.acceptedAt } : {}),
});

// insertPlan adds plan at the right position (end of taken plans or end of all plans).
const insertPlan = (plans, plan) => {
  if (planTaken(plan)) {
    const lastTakenPlanIndex = plans.findIndex((p) => !planTaken(p));
    const insertIndex =
      lastTakenPlanIndex === -1 ? plans.length : lastTakenPlanIndex;
    plans.splice(insertIndex, 0, plan);
  } else {
    plans.push(plan);
  }
};

const insertStep = (steps, step) => {
  if (step.takenAt) {
    const lastStepTakenIndex = steps.findIndex((s) => !s.takenAt);
    const insertIndex =
      lastStepTakenIndex === -1 ? steps.length : lastStepTakenIndex;
    steps.splice(insertIndex, 0, step);
  } else {
    steps.push(step);
  }
};

const extractPlan = (plans, planID) => {
  const planIndex = plans.findIndex((p) => p.id === planID);
  return plans.splice(planIndex, 1)[0];
};

const extractStep = (steps, stepID) => {
  const stepIndex = steps.findIndex((s) => s.id === stepID);
  return steps.splice(stepIndex, 1)[0];
};

const extractPlanAndStep = (state, action) => {
  const newPlans = state.slice();
  const plan = extractPlan(newPlans, action.payload.planID);
  const newSteps = plan.steps.slice();
  const step = extractStep(newSteps, action.payload.stepID);
  return [newPlans, plan, newSteps, step];
};

const updateStep = (state, action, stepReceiver) => {
  const [newPlans, plan, newSteps, step] = extractPlanAndStep(state, action);
  const newStep = stepReceiver(step);
  if (!newStep) return state;
  insertStep(newSteps, newStep);
  insertPlan(newPlans, updateObject(plan, { steps: newSteps }));
  return newPlans;
};

const updatePlan = (state, action, planReceiver) => {
  const newPlans = state.slice();
  const newPlan = planReceiver(
    extractPlan(newPlans, action.payload.planID),
    action
  );
  if (!newPlan) return state;
  insertPlan(newPlans, newPlan);
  return newPlans;
};

const updatePlanSteps = (state, action, planReceiver) => {
  const newPlans = state.slice();
  const planIndex = newPlans.findIndex((p) => p.id === action.payload.planID);
  newPlans[planIndex] = planReceiver(newPlans[planIndex]);
  return newPlans;
};

const updateNotificationPlan = (state, action, planReceiver) => {
  const newPlans = state.slice();
  const notificationPlanIndex = newPlans.findIndex((p) => p.isNotificationPlan);
  const notificationPlan =
    notificationPlanIndex > -1
      ? newPlans.splice(notificationPlanIndex, 1)[0]
      : buildNotificationPlan(
          action.payload.optionalNotificationPlanID,
          'Notifications',
          action.payload.clientCreatedAt
        );
  const newPlan = planReceiver(notificationPlan);
  if (!newPlan) return state;
  insertPlan(newPlans, newPlan);
  return newPlans;
};

export const joinPlans = (plans, planID, addedPlanID) => {
  const newPlans = plans.slice();
  const plan = extractPlan(newPlans, planID);
  const addedPlan = extractPlan(newPlans, addedPlanID);
  const addedSimple = planSimple(addedPlan);
  const addedSteps = addedSimple ? [planToStep(addedPlan)] : addedPlan.steps;
  const concatenated = plan.steps.concat(addedSteps);
  const joinedTaken = concatenated.filter((s) => s.takenAt);
  const joinedUntaken = concatenated.filter((s) => !s.takenAt);
  const joinedPlanTaken = joinedUntaken.length === 0;
  const newPlan = updateObject(plan, {
    steps: joinedTaken.concat(joinedUntaken),
  });
  if (!newPlan.publishedAt) {
    newPlan.steps.forEach((step) => delete step.publishedAt);
  }
  if (!joinedPlanTaken) {
    delete newPlan.takenAt;
  }
  insertPlan(newPlans, newPlan);
  return newPlans;
};

const actionToVerb = (type) => {
  switch (type) {
    case STEP_ACCEPTANCE_NOTIFICATION:
      return 'accepts';
    case STEP_DENIAL_NOTIFICATION:
      return 'denies';
    case STEP_PROPOSAL_NOTIFICATION:
      return 'proposes';
    default:
      return 'unknown';
  }
};

const stepTextForAction = (action) => {
  const { payload } = action;
  return (
    `User ${payload.fromUserID}` +
    ` ${actionToVerb(action.type)} “${
      payload.stepText || payload.remoteStepText
    }”`
  );
};

export const planAndStepExist = (plans, action) => {
  try {
    const coords = action.payload || {};
    if (coords.planID && coords.stepID) {
      for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].id === coords.planID) {
          for (let j = 0; j < plans[i].steps.length; j += 1) {
            if (plans[i].steps[j].id === coords.stepID) {
              return true;
            }
          }
          return false;
        }
      }
      return false;
    }
    if (coords.planID && coords.addedPlanID) {
      const found = [false, false];
      for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].id === coords.planID) {
          found[0] = true;
        }
        if (plans[i].id === coords.addedPlanID) {
          found[1] = true;
        }
        if (found.every((e) => e)) {
          return true;
        }
      }
      return false;
    }
    if (coords.planID) {
      for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].id === coords.planID) {
          return true;
        }
      }
      return false;
    }
  } catch (error) {
    return false;
  }
  return true;
};

const publishPlan = (plan, action) => {
  if (plan.publishedAt) return false;

  const newPlan = updateObject(plan, {
    publishedAt: action.payload.clientCreatedAt,
  });
  if (!newPlan.firstPublishedAt) {
    newPlan.firstPublishedAt = newPlan.publishedAt;
  }
  return newPlan;
};

export const plansReducer = (state = [], action) => {
  if (!planAndStepExist(state, action)) {
    console.log('skipping broken action:', action);

    return state;
  }
  switch (action.type) {
    case STEP_DELETION: {
      const [newPlans, plan, newSteps] = extractPlanAndStep(state, action);
      insertPlan(newPlans, updateObject(plan, { steps: newSteps }));
      return newPlans;
    }
    case STEP_PUBLICATION: {
      const newPlans = updateStep(state, action, (step) => {
        if (step.publishedAt) return false;

        const d = action.payload.clientCreatedAt;
        return updateObject(step, {
          publishedAt: d,
          ...(step.firstPublishedAt ? {} : { firstPublishedAt: d }),
        });
      });
      return updatePlan(newPlans, action, publishPlan);
    }
    case STEP_UNPUBLICATION:
      return updateStep(state, action, (step) => {
        if (!step.publishedAt) return false;

        const newStep = updateObject(step, {});
        delete newStep.publishedAt;
        return newStep;
      });
    case STEP_TAKING:
      return updateStep(state, action, (step) => {
        if (step.takenAt) return false;

        return updateObject(step, { takenAt: action.payload.clientCreatedAt });
      });
    case STEP_ACCEPTANCE:
      return updateStep(state, action, (step) => {
        if (step.acceptedAt) return false;

        return updateObject(step, {
          acceptedAt: action.payload.clientCreatedAt,
        });
      });
    case STEP_ACCEPTANCE_NOTIFICATION:
    case STEP_DENIAL_NOTIFICATION:
    case STEP_PROPOSAL_NOTIFICATION:
      return updateNotificationPlan(state, action, (plan) => {
        const newStep = buildStep(
          action.aid,
          stepTextForAction(action),
          action.payload.clientCreatedAt
        );
        newStep.fromUserID = action.payload.fromUserID;
        const newPlan = updateObject(plan, {
          steps: plan.steps.concat(newStep),
        });
        delete newPlan.takenAt;
        return newPlan;
      });
    case STEP_CREATION: {
      if (action.payload.planID) {
        return updatePlan(state, action, (plan) => {
          const newStep = buildStep(
            action.aid,
            action.payload.stepText,
            action.payload.clientCreatedAt
          );
          const newPlan = updateObject(plan, {
            steps: plan.steps.concat(newStep),
          });
          delete newPlan.takenAt;
          return newPlan;
        });
      }
      const newPlan = buildPlan(
        action.aid,
        action.payload.stepText,
        action.payload.clientCreatedAt
      );
      return state.concat(newPlan);
    }
    case STEP_PROPOSAL:
      return updatePlanSteps(state, action, (plan) => {
        const ts = action.payload.clientCreatedAt;
        const newStep = buildStep(action.aid, action.payload.stepText, ts);
        newStep.fromUserID = action.payload.fromUserID;
        newStep.proposedAt = ts;
        newStep.publishedAt = ts;
        newStep.firstPublishedAt = ts;
        return updateObject(plan, {
          steps: plan.steps.concat(newStep),
        });
      });
    case PLAN_DELETION:
      return state.filter((p) => p.id !== action.payload.planID);
    case PLAN_EXTRACTION: {
      const newPlans = state.slice();
      const plan = extractPlan(newPlans, action.payload.planID);
      const newSteps = plan.steps.slice();
      const step = extractStep(newSteps, action.payload.stepID);
      const newPlan = updateObject(plan, { steps: newSteps });
      const extractedPlan = stepToPlan(step);
      insertPlan(newPlans, newPlan);
      insertPlan(newPlans, extractedPlan);
      return newPlans;
    }
    case PLAN_JOIN:
      return joinPlans(
        state,
        action.payload.planID,
        action.payload.addedPlanID
      );
    case PLAN_PUBLICATION:
      return updatePlan(state, action, publishPlan);
    case PLAN_UNPUBLICATION:
      return updatePlan(state, action, (plan) => {
        if (!plan.publishedAt) return false;

        const newPlan = updateObject(plan, {});
        delete newPlan.publishedAt;
        newPlan.steps = newPlan.steps.map((step) => {
          if (!step.publishedAt) return step;

          const newStep = updateObject(step, {});
          delete newStep.publishedAt;
          return newStep;
        });
        return newPlan;
      });
    case PLAN_TAKING:
      return updatePlan(state, action, (plan) => {
        if (plan.takenAt) return false;

        return updateObject(plan, {
          takenAt: action.payload.clientCreatedAt,
        });
      });
    case ACTION_REVERSION:
    case HYDRATION: {
      return action.payload.hydrated;
    }
    case PLANS_CLEARING: {
      return [];
    }
    default:
      return state;
  }
};

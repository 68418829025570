import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButton = styled.button`
  flex: 0 0 auto;
  position: relative;
`;

const PlanSelected = ({ published, taken, planDeselectionCallback }) => (
  <StyledButton
    className="icon"
    onClick={() => planDeselectionCallback()}
    type="button"
  >
    <svg>
      <use
        xlinkHref={
            published
              ? '#icon-circle-filled-published'
              : (taken ? '#icon-circle-filled-taken' : '#icon-circle-filled-untaken')
          }
      />
    </svg>
  </StyledButton>
);

PlanSelected.propTypes = {
  planDeselectionCallback: PropTypes.func.isRequired,
  taken: PropTypes.bool.isRequired,
  published: PropTypes.bool.isRequired,
};

export default PlanSelected;

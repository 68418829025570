export default (outer, inner) => {
  const top = inner.offsetTop - outer.clientHeight + inner.clientHeight;
  const scrollTo = top > outer.scrollHeight - 12 - outer.clientHeight
    ? outer.scrollHeight
    : top;
  if (outer.scroll) {
    outer.scroll({ top: scrollTo, behavior: 'smooth' });
    return;
  }
  outer.scrollTop(scrollTo);
};

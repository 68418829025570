import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { planTaken } from '../utils/planHelpers';
import { PlanPublishedProps } from '../utils/propTypes';
import StepTake from './StepTake';
import { StepProposed, StepPublished } from './steps';
import StepIconButton from './StepIconButton';
import { StyledPlanPublished } from './plans';

const StyledButton = styled.button`
  flex: 0 0 auto;
  position: relative;
`;

const PlanPublishedExpanded = ({ publishedPlan, selectCallback }) => {
  const onclick = () => selectCallback('');
  const taken = planTaken(publishedPlan);
  const steps = publishedPlan.steps.map((step) => {
    const leftInteraction = <StepIconButton icon={step.takenAt ? 'check' : 'check-outline'} />;
    const rightInteraction = <StepIconButton />;

    if (step.proposedAt && !step.acceptedAt) {
      return (
        <StepProposed
          key={step.id}
          step={step}
          leftInteraction={leftInteraction}
          rightInteraction={rightInteraction}
        />
      );
    }

    return (
      <StepPublished
        key={step.id}
        step={step}
        leftInteraction={leftInteraction}
        rightInteraction={rightInteraction}
      />
    );
  });
  const leftInteraction = (
    <StepTake
      published
      taken={taken}
      clickHandler={() => {}}
    />
  );
  const rightInteraction = (
    <StyledButton onClick={onclick} type="button" className="icon">
      <svg>
        <use
          style={{ flex: '0 0 auto', position: 'relative' }}
          xlinkHref="#icon-circle-filled-published"
        />
      </svg>
    </StyledButton>
  );
  return (
    <StyledPlanPublished
      plan={publishedPlan}
      leftInteraction={leftInteraction}
      rightInteraction={rightInteraction}
      steps={steps}
    />
  );
};

PlanPublishedExpanded.propTypes = {
  publishedPlan: PlanPublishedProps.isRequired,
  selectCallback: PropTypes.func.isRequired,
};

export default PlanPublishedExpanded;

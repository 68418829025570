import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { planStepCount, planTaken } from '../utils/planHelpers';
import { PlanProps } from '../utils/propTypes';

const StyledButton = styled.button`
  flex: 0 0 auto;
  position: relative;
`;

const StyledCount = styled.span`
  display: block;
  font-size: 12px;
  position: absolute;
  text-align: center;
  top: 5px;
`;

const PlanSelectable = ({ plan, planSelectionCallback }) => {
  const stepCount = planStepCount(plan);
  const taken = planTaken(plan);
  const onclick = () => {
    planSelectionCallback(plan.id);
  };
  return (
    <StyledButton className="icon" onClick={onclick} type="button">
      <svg>
        <use xlinkHref={plan.publishedAt
          ? '#icon-circle-published'
          : (taken ? '#icon-circle-taken' : '#icon-circle-untaken')}
        />
      </svg>
      <StyledCount className="icon">
        {stepCount > 0 ? stepCount : ''}
      </StyledCount>
    </StyledButton>
  );
};

PlanSelectable.propTypes = {
  plan: PlanProps.isRequired,
  planSelectionCallback: PropTypes.func.isRequired,
};

export default PlanSelectable;

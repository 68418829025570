import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledDiv = styled.div`
  border-bottom: 1px solid #efefef;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  width: 100%;

  p {
  }
`;

const StyledTopic = styled.span`
  &:not(:first-child):before {
    content: ',';
  }
`;

const StyledCheckbox = styled.input`
  appearance: none;
  display: none;
`;

const StyledButton = styled.button`
  flex: 0 0 auto;
  position: relative;
  vertical-align: text-top;
  margin-right: 4px;
  margin-top: 2px;
`;

export default ({
  userName,
  availableTopics,
  selectedTopics,
  toggleTopicCallback,
}) => {
  if ((availableTopics || []).length === 0) {
    return <></>;
  }
  return (
    <StyledDiv>
      <p>
        {userName}'s topics are:&nbsp;
        {(availableTopics || []).map((topic) => {
          return (
            <StyledTopic key={topic}>
              <StyledCheckbox
                id={topic}
                type="checkbox"
                checked={selectedTopics.indexOf(topic) > -1}
                onChange={() => toggleTopicCallback(topic)}
              ></StyledCheckbox>
              <label htmlFor={topic}>
                <StyledButton
                  type="button"
                  className="icon"
                  onClick={() => toggleTopicCallback(topic)}
                >
                  <svg>
                    {selectedTopics.indexOf(topic) > -1 ? (
                      <use xlinkHref="#icon-checkbox-checked" />
                    ) : (
                      <use xlinkHref="#icon-checkbox" />
                    )}
                  </svg>
                </StyledButton>
                {topic}
              </label>
            </StyledTopic>
          );
        })}
        .
      </p>
    </StyledDiv>
  );
};

export const planStepCount = (plan) => plan.steps.length;
export const planAllStepsTaken = (plan) => !plan.steps.find((step) => !step.takenAt);
export const planTaken = (plan) => !!plan.takenAt;
export const findPlan = (plans, planID) => plans.find((plan) => plan.id === planID);
export const findPlanLastStepID = (plans, planID) => {
  const plan = findPlan(plans, planID);
  if (plan) return plan.steps[plan.steps.length - 1].id;
  return '';
};
export const planSimple = (plan) => plan.steps.length === 0;
export const truncateString = (str, n) => {
  if (str.length <= n) return str;
  return `${str.slice(0, n)}…`;
};

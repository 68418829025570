import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PlanProps } from '../utils/propTypes';
import { planTaken } from '../utils/planHelpers';
import { planJoin } from '../redux/actions';

const StyledButton = styled.button`
  flex: 0 0 auto;
  position: relative;
`;

const PlanAddable = ({ plan, selectedPlanID, actionCallback }) => {
  const taken = planTaken(plan);
  return (
    <StyledButton
      className="icon"
      onClick={() => actionCallback(planJoin(selectedPlanID, plan.id))}
      type="button"
    >
      <svg>
        <use
          xlinkHref={plan.publishedAt
            ? '#icon-circle-plus-published'
            : (taken ? '#icon-circle-plus-taken' : '#icon-circle-plus-untaken')}
        />
      </svg>
    </StyledButton>
  );
};

PlanAddable.propTypes = {
  actionCallback: PropTypes.func.isRequired,
  plan: PlanProps.isRequired,
  selectedPlanID: PropTypes.string.isRequired,
};

export default PlanAddable;

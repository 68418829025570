import React from 'react';
import PropTypes from 'prop-types';
import PlanSelectable from './PlanSelectable';
import PlanAddable from './PlanAddable';
import { PlanProps } from '../utils/propTypes';
import StepTake from './StepTake';
import * as Constants from '../constants';

import {
  planDeletion,
  planTaking,
  planPublication,
  planUnpublication,
} from '../redux/actions';
import { planSimple, planTaken } from '../utils/planHelpers';
import {
  StyledPlanPublished,
  StyledPlanTaken,
  StyledPlanUntaken,
} from './plans';

// FlipMove requires Plan to be a class, not a functional component.
class Plan extends React.Component {
  render() {
    const {
      proposeStepTextCallback,
      plan,
      selectedPlanID,
      actionCallback,
      planSelectionCallback,
    } = this.props;
    const simple = planSimple(plan);
    const taken = planTaken(plan);
    const selectable = selectedPlanID === '';
    const swiperightHandler = plan.publishedAt
      ? null
      : {
          handler: () => actionCallback(planPublication(plan.id)),
          color: Constants.PublishedLabel,
          label: 'Publish',
        };
    const swipeleftHandler = plan.publishedAt
      ? {
          handler: () => actionCallback(planUnpublication(plan.id)),
          color: taken ? Constants.TakenLabel : Constants.UntakenLabel,
          label: 'Unpublish',
        }
      : {
          handler: () => actionCallback(planDeletion(plan.id)),
          color: Constants.DeletedLabel,
          label: 'Delete',
        };
    const doubletapHandler = () => proposeStepTextCallback(plan.name);
    const StyledPlan = plan.publishedAt
      ? StyledPlanPublished
      : taken
      ? StyledPlanTaken
      : StyledPlanUntaken;
    const leftInteraction =
      !taken && !simple ? (
        <div className="icon" />
      ) : (
        <StepTake
          published={!!plan.publishedAt}
          taken={taken}
          clickHandler={() => actionCallback(planTaking(plan.id))}
        />
      );
    const rightInteraction = selectable ? (
      <PlanSelectable
        plan={plan}
        planSelectionCallback={planSelectionCallback}
      />
    ) : (
      <PlanAddable
        plan={plan}
        selectedPlanID={selectedPlanID}
        actionCallback={actionCallback}
      />
    );
    return (
      <StyledPlan
        plan={plan}
        leftInteraction={leftInteraction}
        rightInteraction={rightInteraction}
        steps={<></>}
        swipeHandlers={{
          right: swiperightHandler,
          left: swipeleftHandler,
          doubletap: doubletapHandler,
        }}
      />
    );
  }
}

Plan.propTypes = {
  actionCallback: PropTypes.func.isRequired,
  proposeStepTextCallback: PropTypes.func.isRequired,
  plan: PlanProps.isRequired,
  selectedPlanID: PropTypes.string.isRequired,
  planSelectionCallback: PropTypes.func.isRequired,
};

export default Plan;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import FlipMove from 'react-flip-move';
import Plan from '../components/Plan';
import PlanExpanded from '../components/PlanExpanded';
import { PlanProps } from '../utils/propTypes';
import generateTutorial from '../utils/generateTutorial';

const StyledA = styled.a`
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -100px;
  text-align: center;
  width: 200px;
  display: inline-block;
  cursor: pointer;
`;

const StyledFlipMove = styled(FlipMove)`
  margin: 0;
  padding: 0;
  position: relative;
`;

class Plans extends React.Component {
  // componentDidMount() {
  //   const { scrollToPlanCallback } = this.props;
  //   this.scroller.onscroll = () => scrollToPlanCallback();
  // }

  render() {
    const {
      actionCallback,
      proposeStepTextCallback,
      planDeselectionCallback,
      plans,
      planSelectionCallback,
      selectedPlanID,
      isSignedIn,
    } = this.props;
    const emptyPlaceholder = isSignedIn
      ? <StyledA>Loading… (or no plans yet.)</StyledA>
      : <StyledA onClick={() => actionCallback(...generateTutorial())}>Start tutorial!</StyledA>;
    const planItems = plans.map((plan) => {
      if (plan.id === selectedPlanID) {
        return (
          <PlanExpanded
            key={plan.id}
            plan={plan}
            actionCallback={actionCallback}
            proposeStepTextCallback={proposeStepTextCallback}
            planDeselectionCallback={planDeselectionCallback}
          />
        );
      }
      return (
        <Plan
          key={plan.id}
          plan={plan}
          selectedPlanID={selectedPlanID}
          actionCallback={actionCallback}
          proposeStepTextCallback={proposeStepTextCallback}
          planSelectionCallback={planSelectionCallback}
        />
      );
    });
    return (
      <StyledFlipMove
        id="plans"
        ref={(node) => { (this.scroller = node); }}
        leaveAnimation={false}
      >
        {
          planItems.length > 0 ? planItems : emptyPlaceholder
        }
      </StyledFlipMove>
    );
  }
}

Plans.propTypes = {
  actionCallback: PropTypes.func.isRequired,
  proposeStepTextCallback: PropTypes.func.isRequired,
  planSelectionCallback: PropTypes.func.isRequired,
  planDeselectionCallback: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(PlanProps).isRequired,
  selectedPlanID: PropTypes.string.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  plans: state.plans,
  isSignedIn: state.client.isSignedIn,
});

export default connect(
  mapStateToProps,
)(Plans);

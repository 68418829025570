import PropTypes from 'prop-types';

export const ActionProps = PropTypes.shape({
  aid: PropTypes.string,
  type: PropTypes.string,
});

export const SwipeHandler = PropTypes.exact({
  handler: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
});

export const SwipeHandlers = PropTypes.exact({
  doubletap: PropTypes.func.isRequired,
  left: SwipeHandler.isRequired,
  right: SwipeHandler,
});

export const StepProps = PropTypes.exact({
  acceptedAt: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  fromUserID: PropTypes.number,
  firstPublishedAt: PropTypes.string,
  id: PropTypes.string.isRequired,
  proposedAt: PropTypes.string,
  publishedAt: PropTypes.string,
  takenAt: PropTypes.string,
  text: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(PropTypes.string),
});

export const PlanProps = PropTypes.exact({
  acceptedAt: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  fromUserID: PropTypes.number,
  userID: PropTypes.number,
  firstPublishedAt: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  proposedAt: PropTypes.string,
  publishedAt: PropTypes.string,
  steps: PropTypes.arrayOf(StepProps).isRequired,
  takenAt: PropTypes.string,
  isNotificationPlan: PropTypes.bool,
  topics: PropTypes.arrayOf(PropTypes.string),
});

export const PlanPublishedProps = PropTypes.shape({
  name: PropTypes.string.isRequired,
  userID: PropTypes.number.isRequired,
});

export const post = (path, csrfToken, data) => fetch(
  path,
  {
    headers: {
      'content-type': 'application/json; charset=UTF-8',
      'X-CSRF-TOKEN': csrfToken,
    },
    body: JSON.stringify(data),
    method: 'POST',
    credentials: 'same-origin',
  },
);

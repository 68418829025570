import React from 'react';
import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';
import PlanSelected from './PlanSelected';
import Step from './Step';
import { planAllStepsTaken, planSimple, planTaken } from '../utils/planHelpers';
import StepTake from './StepTake';
import { PlanProps } from '../utils/propTypes';
import { planTaking } from '../redux/actions';
import { StyledPlanPublished, StyledPlanTaken, StyledPlanUntaken } from './plans';

// FlipMove requires PlanExpanded to be a class, not a functional component.
class PlanExpanded extends React.Component {
  render() {
    const {
      actionCallback, plan, planDeselectionCallback, proposeStepTextCallback,
    } = this.props;
    const simple = planSimple(plan);
    const taken = planTaken(plan);
    const allStepsTaken = planAllStepsTaken(plan);
    const stepElems = plan.steps.map((step) => (
      <Step
        key={step.id}
        plan={plan}
        step={step}
        actionCallback={actionCallback}
        proposeStepTextCallback={proposeStepTextCallback}
      />
    ));
    const StyledPlan = plan.publishedAt ? StyledPlanPublished : (taken ? StyledPlanTaken : StyledPlanUntaken);
    const leftInteraction = simple || allStepsTaken ? (
      <StepTake
        published={!!plan.publishedAt}
        taken={taken}
        clickHandler={() => actionCallback(planTaking(plan.id))}
      />
    ) : (
      <div className="icon" />
    );
    const rightInteraction = (
      <PlanSelected
        published={!!plan.publishedAt}
        planDeselectionCallback={planDeselectionCallback}
        taken={taken}
      />
    );
    return (
      <StyledPlan
        plan={plan}
        leftInteraction={leftInteraction}
        rightInteraction={rightInteraction}
        steps={<FlipMove>{stepElems}</FlipMove>}
      />
    );
  }
}

PlanExpanded.propTypes = {
  actionCallback: PropTypes.func.isRequired,
  proposeStepTextCallback: PropTypes.func.isRequired,
  planDeselectionCallback: PropTypes.func.isRequired,
  plan: PlanProps.isRequired,
};

export default PlanExpanded;

import React from 'react';

export default ({ onClick, icon }) => (
  <button type="button" onClick={onClick}>
    <svg>
      <use
        xlinkHref={`#icon-${icon}`}
      />
    </svg>
  </button>
);

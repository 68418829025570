export const ProposedLight = '#fbe3f5';
export const ProposedNormal = '#BFF3E0';
export const ProposedDark = '#007744';

export const PublishedLight = '#E3F6F6';
export const PublishedNormal = '#E3F6F6';
export const PublishedDark = '#004477';
export const PublishedLabel = '#d3e6e6';

export const UntakenLight = '#fff2c9';
export const UntakenNormal = '#fff2c9';
export const UntakenDark = '#b66100';
export const UntakenLabel = '#efe2b9';

export const TakenLight = '#f3f3f3';
export const TakenNormal = '#f3f3f3';
export const TakenDark = '#484848';
export const TakenLabel = '#e3e3e3';

export const DeletedLabel = '#e3e3e3';

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Constants from '../constants';
import Swipable from './Swipable';

const Margin = styled.div`
  margin: 12px;

  @media screen and (max-width: 640px) {
    margin: 12px 2%;
  }
`;

const StyledGroup = styled.div`
  background-color: ${({ colors }) => colors.light};
  border-color: ${({ colors }) => colors.normal};
  border-radius: 16px;
  border: 1px solid ${({ colors }) => colors.normal};
  color: ${({ colors }) => colors.dark};
`;

const StyledPlan = styled.div`
  display: flex;
  min-width: 1px;
  padding: 6px;
`;

const StyledName = styled.div`
  flex: 1 1 auto;
  padding: 4px 6px 0 6px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const customizedPlan = (colors) => ({
  plan,
  leftInteraction,
  rightInteraction,
  steps,
  swipeHandlers,
}) =>
  swipeHandlers ? (
    <Margin id={plan.id}>
      <Swipable swipeHandlers={swipeHandlers}>
        <StyledGroup colors={colors}>
          <StyledPlan>
            {leftInteraction}
            <StyledName>{plan.name}</StyledName>
            {rightInteraction}
          </StyledPlan>
        </StyledGroup>
      </Swipable>
      {steps}
    </Margin>
  ) : (
    <Margin id={plan.id}>
      <StyledGroup colors={colors}>
        <StyledPlan>
          {leftInteraction}
          <StyledName>{plan.name}</StyledName>
          {rightInteraction}
        </StyledPlan>
        {steps}
      </StyledGroup>
    </Margin>
  );

export const StyledPlanPublished = customizedPlan({
  light: Constants.PublishedLight,
  normal: Constants.PublishedNormal,
  dark: Constants.PublishedDark,
});

export const StyledPlanTaken = customizedPlan({
  light: Constants.TakenLight,
  normal: Constants.TakenNormal,
  dark: Constants.TakenDark,
});

export const StyledPlanUntaken = customizedPlan({
  light: Constants.UntakenLight,
  normal: Constants.UntakenNormal,
  dark: Constants.UntakenDark,
});

import { v4 } from 'uuid';

export const ACTION_REVERSION = 'ACTION_REVERSION';
export const HYDRATION = 'HYDRATION';
export const PLAN_DELETION = 'PLAN_DELETION';
export const PLAN_EXTRACTION = 'PLAN_EXTRACTION';
export const PLAN_JOIN = 'PLAN_JOIN';
export const PLAN_PUBLICATION = 'PLAN_PUBLICATION';
export const PLAN_TAKING = 'PLAN_TAKING';
export const PLAN_UNPUBLICATION = 'PLAN_UNPUBLICATION';
export const PLANS_CLEARING = 'PLANS_CLEARING';
export const STEP_ACCEPTANCE = 'STEP_ACCEPTANCE';
export const STEP_ACCEPTANCE_NOTIFICATION = 'STEP_ACCEPTANCE_NOTIFICATION';
export const STEP_ACCEPTANCE_NOTIFICATION_CREATION =
  'STEP_ACCEPTANCE_NOTIFICATION_CREATION';
export const STEP_CREATION = 'STEP_CREATION';
export const STEP_DELETION = 'STEP_DELETION';
export const STEP_DENIAL_NOTIFICATION = 'STEP_DENIAL_NOTIFICATION';
export const STEP_DENIAL_NOTIFICATION_CREATION =
  'STEP_DENIAL_NOTIFICATION_CREATION';
export const STEP_PROPOSAL = 'STEP_PROPOSAL';
export const STEP_PROPOSAL_CREATION = 'STEP_PROPOSAL_CREATION';
export const STEP_PROPOSAL_NOTIFICATION = 'STEP_PROPOSAL_NOTIFICATION';
export const STEP_PUBLICATION = 'STEP_PUBLICATION';
export const STEP_TAKING = 'STEP_TAKING';
export const STEP_UNPUBLICATION = 'STEP_UNPUBLICATION';

export const PUBLISHED_PLANS_OVERRIDE = 'PUBLISHED_PLANS_OVERRIDE';
export const PUBLISHED_PLAN_SELECTION = 'PUBLISHED_PLAN_SELECTION';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const TOGGLE_TOPIC = 'TOGGLE_TOPIC';
export const TOPICS_OVERRIDE = 'TOPICS_OVERRIDE';
export const USER_SELECTION = 'USER_SELECTION';

export const SCROLLABLE_ACTIONS = [PLAN_JOIN, STEP_CREATION];
export const REVERTABLE_ACTIONS = [
  ACTION_REVERSION,
  PLAN_DELETION,
  PLAN_EXTRACTION,
  PLAN_JOIN,
  PLAN_PUBLICATION,
  PLAN_TAKING,
  PLAN_UNPUBLICATION,
  STEP_ACCEPTANCE,
  STEP_CREATION,
  STEP_DELETION,
  STEP_PUBLICATION,
  STEP_TAKING,
  STEP_UNPUBLICATION,
];

export const REVERTABLE_LABELS = {
  [ACTION_REVERSION]: 'You reverted an action.',
  [PLAN_DELETION]: 'Plan deleted.',
  [PLAN_EXTRACTION]: 'You extracted a step.',
  [PLAN_JOIN]: 'You joined 2 plans into 1.',
  [PLAN_PUBLICATION]: 'Plan marked for publication.',
  [PLAN_TAKING]: 'You took a plan.',
  [PLAN_UNPUBLICATION]: 'Plan marked for unpublication.',
  [STEP_ACCEPTANCE]: 'You accepted a proposed step.',
  [STEP_CREATION]: 'You created a step.',
  [STEP_DELETION]: 'You deleted a step.',
  [STEP_PROPOSAL]: 'You proposed a step.',
  [STEP_PUBLICATION]: 'Step marked for publication.',
  [STEP_TAKING]: 'You took a step.',
  [STEP_UNPUBLICATION]: 'Step marked for unpublication.',
};

export const authorizeActions = (actions, isSignedIn) => {
  if (isSignedIn) return actions;

  return actions.filter(
    (action) => ![PLAN_PUBLICATION, STEP_PUBLICATION].includes(action.type)
  );
};

export const plansClearing = () => ({ type: PLANS_CLEARING });

export const hydration = (hydrated) => ({
  type: HYDRATION,
  payload: { hydrated },
});

const wrapAction = (action) => ({
  type: action.type,
  aid: v4(),
  new: true, // new should be posted to server.
  payload: {
    ...action.payload,
    ...{ clientCreatedAt: new Date().toISOString() },
  },
});

export const planDeletion = (planID) =>
  wrapAction({ type: PLAN_DELETION, payload: { planID } });
export const actionReversion = (revertedAID) =>
  wrapAction({ type: ACTION_REVERSION, payload: { revertedAID } });

export const planExtraction = (planID, stepID) =>
  wrapAction({ type: PLAN_EXTRACTION, payload: { planID, stepID } });

export const planJoin = (planID, addedPlanID) =>
  wrapAction({
    type: PLAN_JOIN,
    payload: { planID, addedPlanID },
  });

export const planTaking = (planID) =>
  wrapAction({ type: PLAN_TAKING, payload: { planID } });

export const planPublication = (planID) =>
  wrapAction({ type: PLAN_PUBLICATION, payload: { planID } });

export const planUnpublication = (planID) =>
  wrapAction({ type: PLAN_UNPUBLICATION, payload: { planID } });

export const stepAcceptance = (planID, stepID) =>
  wrapAction({ type: STEP_ACCEPTANCE, payload: { planID, stepID } });

export const stepAcceptanceNotificationCreation = (
  planID,
  stepID,
  stepText,
  toUserID
) =>
  wrapAction({
    type: STEP_ACCEPTANCE_NOTIFICATION_CREATION,
    payload: {
      planID,
      stepID,
      stepText,
      toUserID,
    },
  });

export const stepDenialNotificationCreation = (
  planID,
  stepID,
  stepText,
  toUserID
) =>
  wrapAction({
    type: STEP_DENIAL_NOTIFICATION_CREATION,
    payload: {
      planID,
      stepID,
      stepText,
      toUserID,
    },
  });

export const stepCreation = (planID, stepText) =>
  wrapAction({
    type: STEP_CREATION,
    payload: { stepText, ...(planID !== '' ? { planID } : {}) },
  });

export const stepDeletion = (planID, stepID) =>
  wrapAction({ type: STEP_DELETION, payload: { planID, stepID } });

export const stepProposalCreation = (remotePlanID, stepText, toUserID) =>
  wrapAction({
    type: STEP_PROPOSAL_CREATION,
    payload: { remotePlanID, stepText, toUserID },
  });

export const stepPublication = (planID, stepID) =>
  wrapAction({ type: STEP_PUBLICATION, payload: { planID, stepID } });

export const stepUnpublication = (planID, stepID) =>
  wrapAction({ type: STEP_UNPUBLICATION, payload: { planID, stepID } });

export const stepTaking = (planID, stepID) =>
  wrapAction({ type: STEP_TAKING, payload: { planID, stepID } });

// The following actions will be created in backend via …Creation actions and
// are just here for completeness/testing purposes.
export const stepProposal = (planID, stepText, fromUserID) =>
  wrapAction({
    type: STEP_PROPOSAL,
    payload: { planID, stepText, fromUserID },
  });

export const stepAcceptanceNotification = (
  remotePlanID,
  remoteStepID,
  remoteStepText,
  fromUserID,
  optionalNotificationPlanID
) =>
  wrapAction({
    type: STEP_ACCEPTANCE_NOTIFICATION,
    payload: {
      remotePlanID,
      remoteStepID,
      remoteStepText,
      fromUserID,
      optionalNotificationPlanID,
    },
  });

export const stepDenialNotification = (
  remotePlanID,
  remoteStepID,
  remoteStepText,
  fromUserID,
  optionalNotificationPlanID
) =>
  wrapAction({
    type: STEP_DENIAL_NOTIFICATION,
    payload: {
      remotePlanID,
      remoteStepID,
      remoteStepText,
      fromUserID,
      optionalNotificationPlanID,
    },
  });

export const stepProposalNotification = (
  planID,
  stepID,
  stepText,
  fromUserID,
  optionalNotificationPlanID
) =>
  wrapAction({
    type: STEP_PROPOSAL_NOTIFICATION,
    payload: {
      planID,
      stepID,
      stepText,
      fromUserID,
      optionalNotificationPlanID,
    },
  });

export const publishedPlansOverride = (publishedPlans) => {
  if (!Array.isArray(publishedPlans)) {
    console.error('publishedPlans is not an array', publishedPlans);
  }

  return {
    type: PUBLISHED_PLANS_OVERRIDE,
    payload: {
      publishedPlans,
    },
  };
};

export const publishedPlanSelection = (selectedPublishedPlanID) => ({
  type: PUBLISHED_PLAN_SELECTION,
  payload: {
    selectedPublishedPlanID,
  },
});

export const signIn = () => ({ type: SIGN_IN });
export const signOut = () => ({ type: SIGN_OUT });

export const toggleTopic = (topic) => ({
  type: TOGGLE_TOPIC,
  payload: { topic },
});

export const topicsOverride = (topics) => ({
  type: TOPICS_OVERRIDE,
  payload: { topics },
});

export const userSelection = (shownUserID) => ({
  type: USER_SELECTION,
  payload: {
    shownUserID: parseInt(shownUserID, 10),
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButton = styled.button`
  flex: 0 0 auto;
  position: relative;
`;

const StepTake = ({ published, taken, clickHandler }) => {
  if (published && taken) {
    return (
      <StyledButton type="button" className="icon">
        <svg>
          <use xlinkHref="#icon-check-published" />
        </svg>
      </StyledButton>
    );
  }

  if (published) {
    return (
      <StyledButton type="button" className="icon" onClick={clickHandler}>
        <svg>
          <use xlinkHref="#icon-check-outline-published" />
        </svg>
      </StyledButton>
    );
  }

  if (taken) {
    return (
      <StyledButton type="button" className="icon">
        <svg>
          <use xlinkHref="#icon-check-taken" />
        </svg>
      </StyledButton>
    );
  }

  return (
    <StyledButton type="button" className="icon" onClick={clickHandler}>
      <svg>
        <use xlinkHref="#icon-check-outline-untaken" />
      </svg>
    </StyledButton>
  );
};

StepTake.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  taken: PropTypes.bool.isRequired,
  published: PropTypes.bool.isRequired,
};

export default StepTake;
